import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-columbia-south-carolina.png'
import image0 from "../../images/cities/scale-model-of-classic-travel-network-in-columbia-south-carolina.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Columbia'
            state='South Carolina'
            image={image}
            lat='38.9517053'
            lon='-92.33407239999997'
            attractions={ [{"name": "Classic Travel Network", "vicinity": "2 Office Park Ct, Columbia", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 34.0986965, "lng": -80.92163240000002}] }
            attractionImages={ {"Classic Travel Network":image0,} }
       />);
  }
}